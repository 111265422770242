import axios from "axios";
import {config} from '../utils/apiEndPoint';
import { jwtDecode } from "jwt-decode";

let refreshTokenRequest = null;

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('accessToken');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


axiosInstance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            if (!refreshTokenRequest) {
                refreshTokenRequest = refreshToken();
            }
            const newaccessToken = await refreshTokenRequest;
            refreshTokenRequest = null;

            if (newaccessToken) {
                localStorage.setItem('accessToken', newaccessToken);
                axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newaccessToken}`;
                originalRequest.headers['Authorization'] = `Bearer ${newaccessToken}`;
                return axiosInstance(originalRequest);
            }
        }
        return Promise.reject(error);
    }
);


const refreshToken = async () => {
    try {  
        const refreshToken = localStorage.getItem('refresh-token');
        const response = await axios.post(config?.authentication?.refreshToken, { refresh: refreshToken });
        return response.data.access;
    } catch (error) {
        window.location.href = '/sign-in';
        return null;
    }
};

export default axiosInstance;