import { useState, useEffect, useMemo } from 'react'
import { Table, Pagination, Select } from 'components/ui'
import axiosInstance from 'services/_authHttp'
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    flexRender,
} from '@tanstack/react-table'
import './Pickup.css'
import { config } from 'utils/apiEndPoint'

const { Tr, Th, Td, THead, TBody } = Table

const pageSizeOption = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 30, label: '30 / page' },
    { value: 40, label: '40 / page' },
    { value: 50, label: '50 / page' },
]

const PickupReq = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    // Fetching data from the correct API and filtering by status
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(
                    'https://api.shipcluescargo.com/shipcargo/shipments/all_status_shipments',
                    {
                        params: { status: 'pending_for_pickup' },
                    }
                )
                const apiData = response.data.shipments || []
                const transformedData = apiData.map((item) => ({
                    pickupId: item.id,
                    referenceNo: item.invoice_number,
                    deliveryPartner: '',
                    receiver_contact_person_name:
                        item.receiver_contact_person_name || '',
                    totalBoxes: item.units,
                    pickupDetails: `${item.from_city} to ${item.to_city}`,
                    pickupScheduledOn: item.order_ready_date,
                    status: 'Pickup Pending',
                    pickupAddress: item.from_city,
                }))
                
                setData(transformedData)
                setLoading(false)
            } catch (error) {
                console.error('Error fetching pickup requests:', error)
                setLoading(false)
            }
        }

        fetchData()
    }, [])

    const columns = useMemo(
        () => [
            { header: 'Pickup Id', accessorKey: 'pickupId' },
            { header: 'Pickup Reference No.', accessorKey: 'referenceNo' },
            { header: 'Delivery Partner', accessorKey: 'deliveryPartner' },
            {
                header: 'Contact Person Details',
                accessorKey: 'receiver_contact_person_name',
            },
            { header: 'Total Boxes (wt. in Kg)', accessorKey: 'totalBoxes' },
            { header: 'Pickup Details', accessorKey: 'pickupDetails' },
            { header: 'Pickup Scheduled On', accessorKey: 'pickupScheduledOn' },
            {
                header: 'Status',
                accessorKey: 'status',
                cell: ({ getValue }) => {
                    const status = getValue()
                    const statusClass =
                        status === 'Pickup Pending' ? 'status-ready' : ''
                    return <span className={statusClass}>{status}</span>
                },
            },
            { header: 'Pickup Address', accessorKey: 'pickupAddress' },
        ],
        []
    )

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    const onPaginationChange = (page) => {
        table.setPageIndex(page - 1)
    }

    const onSelectChange = (value = 0) => {
        table.setPageSize(Number(value))
    }

    return (
        <div className="max-h-[500px] overflow-y-auto">
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <Table className="table-auto">
                        <THead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <Tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <Th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                        </Th>
                                    ))}
                                </Tr>
                            ))}
                        </THead>
                        <TBody>
                            {table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <Tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <Td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </Td>
                                        ))}
                                    </Tr>
                                ))
                            ) : (
                                <Tr>
                                    <Td
                                        colSpan={columns.length}
                                        className="text-center py-4"
                                    >
                                        No data available
                                    </Td>
                                </Tr>
                            )}
                        </TBody>
                    </Table>
                    <div className="flex items-center justify-between mt-4">
                        <Pagination
                            pageSize={table.getState().pagination.pageSize}
                            currentPage={
                                table.getState().pagination.pageIndex + 1
                            }
                            total={data.length}
                            onChange={onPaginationChange}
                        />
                        <div style={{ minWidth: 130 }}>
                            <Select
                                size="sm"
                                isSearchable={false}
                                value={pageSizeOption.find(
                                    (option) =>
                                        option.value ===
                                        table.getState().pagination.pageSize
                                )}
                                options={pageSizeOption}
                                onChange={(option) =>
                                    onSelectChange(option?.value)
                                }
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default PickupReq
