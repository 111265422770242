// import { createSlice } from '@reduxjs/toolkit'

// export const sessionSlice = createSlice({
//     name: 'auth/session',
//     initialState: {
//         token: null,
//         signedIn: false,
//     },
//     reducers: {
//         onSignInSuccess: (state, action) => {
//             state.signedIn = true
//             state.token = action.payload
//         },
//         onSignOutSuccess: (state) => {
//             state.signedIn = false
//             state.token = null
//         },
//         setToken: (state, action) => {
//             state.token = action.payload
//         },
//     },
// })

// export const { onSignInSuccess, onSignOutSuccess, setToken } =
//     sessionSlice.actions

// export default sessionSlice.reducer


import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        token: null,
        signedIn: false,
        error: null, // Add error field to track error messages
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true;
            state.token = action.payload;
            state.error = null; // Clear error on successful sign-in
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false;
            state.token = null;
            state.error = null; // Clear error on sign-out
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        onSignInError: (state, action) => {
            state.error = action.payload; // Set error message on sign-in failure
        },
    },
});

export const { onSignInSuccess, onSignOutSuccess, setToken, onSignInError } = sessionSlice.actions;

export default sessionSlice.reducer;
