import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    username: '',
    first_name: '',
    wallet_balance: 0,
    authority: [],
    access: '',
    email: '',
    last_name: '',
    user: '',
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            return {
                ...state,
                ...action.payload,
                userName: action.payload?.user,
            }
        },
        updateWallet: (state, action) => {
            return {
                ...state,
                wallet_balance: action.payload,
            }
        },
        // setShipmentId: (state, action) => {
        //     return {
        //         ...state,
        //         shipment_id: action.payload,
        //     }
        // },

        // Add other reducers if necessary
    },
})

export const { setUser, updateWallet } = userSlice.actions
export default userSlice.reducer
